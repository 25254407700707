<template>
  <div>
    <v-navigation-drawer
      v-cloak
      class="core-drawer green-bg"
      width="256px"
      :permanent="$vuetify.breakpoint.mdAndUp"
      mobileBreakpoint="2400"
      v-model="drawer"
      :mini-variant.sync="mini"
      mini-variant-width="67"
    >
      <div
        class="d-flex align-center mt-8"
        style="margin-bottom: 42px"
        @click="mini = !mini"
      >
        <Logo class="drawer-logo mr-auto" />
        <v-btn icon>
          <img
            src="@/assets/images/chevron-left.svg"
            width="24px"
            height="24px"
            class="mr-4 drawer-arrow"
          />
        </v-btn>
      </div>
      <v-list nav>
        <v-list-item-group
          v-model="item"
          :class="{ 'small-font': items.some((i) => $t(`${i.title}`).length > 20) }"
        >
          <v-list-item
            :class="selected === i.title ? 'v-list-item--active' : ''"
            v-for="i in items.filter((i) => i.show === true)"
            :key="i.title"
            :to="i.link"
          >
            <v-list-item-icon>
              <img
                width="24px"
                height="24px"
                class="v-icon"
                :src="
                  i.title === selected ? selectedIcons[i.title] : defaultIcons[i.title]
                "
              />
            </v-list-item-icon>
            <v-list-item-content
              :style="selected === i.title ? { color: '#00746b' } : { color: 'white' }"
            >
              <v-list-item-title>{{ $t(`${i.title}`) }}</v-list-item-title>
              <span
                class="status-icon requested white--text pa-4 ml-3 d-flex align-center justify-center"
                v-if="i.title === 'devices' && !!offlineDevices.length"
              >
                {{ offlineDevices }}
              </span>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="($store.getters['app/isItt'] || $store.getters['app/isUtaggoPOC2'] || $store.getters['app/username'] === 'demo_tw') && $store.getters['app/hasPromoAccess']"
            key="mobilepromonew"
            href="https://corporate.parkingbnb.world/new"
          >
            <v-list-item-icon>
              <img
                width="24px"
                height="24px"
                class="v-icon"
                :src="defaultIcons.promo"
              />
            </v-list-item-icon>
            <v-list-item-content
              :style="{ color: 'white' }"
            >
              <v-list-item-title>{{ $t('mobile_promo') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-toolbar absolute top class="drawer-icon" @click="drawer = !drawer">
      <v-app-bar-nav-icon class="drawer-burger ml-0 mr-4"></v-app-bar-nav-icon>
      <h4 class="main-title white--text">{{ shortname }}</h4>
    </v-toolbar>
  </div>
</template>

<script>
import Logo from "@/components/core/Logo";

export default {
  name: "Drawer",
  components: {
    Logo,
  },
  computed: {
    selected() {
      return this.$store.state.app.tab;
    },
    isCompany() {
      return this.$store.getters["app/isCompany"];
    },
    places() {
      return this.$store.state.app.places;
    },
    company() {
      return this.$store.state.app.user.company_name;
    },
    shortname() {
      let shortname = "";
      if (this.isCompany) {
        this.company.split(" ").map((word) => {
          shortname = shortname + word.charAt(0);
        });
      } else if (this.places[0]) {
        this.places[0].place_name.split(" ").map((word) => {
          shortname = shortname + word.charAt(0);
        });
      }
      return shortname;
    },
    offlineDevices() {
      return this.$store.state.devices.list.filter((i) => i.online === false);
    },
    items() {
      return [
        {
          title: "dashboard",
          link: "/platform/dashboard",
          show:
            (this.$store.getters["app/isAdmin"] ||
              ((this.$store.getters["app/isItt"] || this.$store.getters["app/isGoldin"]) && this.$store.getters["app/isGuard"])) &&
            !this.$store.getters["app/isDemo"],
        },
        {
          title: "your_parking",
          link: "/platform/your-parking",
          show:
            this.$store.getters["app/isAdmin"] &&
            !this.$store.getters["app/isDemo"] &&
            !this.$store.getters["app/hasSimpleHistory"] &&
            !this.$store.getters["app/isMonthlyLimited"] &&
            !this.$store.getters["app/isSpot"],
        },
        {
          title: "subscriptions",
          link: "/platform/subscriptions",
          show:
            (this.$store.getters["app/isCyber"] ||
              this.$store.getters["app/hasMonthlyWA"]) &&
            !this.$store.getters["app/isDemo"],
        },
        {
          title: "over_parking",
          link: "/platform/over-parking",
          show:
            this.$store.getters["app/isAdmin"] &&
            this.$store.getters["app/hasOverParking"] &&
            !this.$store.getters["app/isDemo"],
        },
        {
          title: "parking_history",
          link: "/platform/parking-history",
          show: !this.$store.getters["app/isDemo"] && !this.$store.getters["app/isGuardLimit"],
        },
        {
          title: "zone_history",
          link: "/platform/zone-history",
          show: this.$store.getters["app/hasZones"] && !this.$store.getters["app/isDemo"],
        },
        {
          title: "visit_history",
          link: "/platform/visits-history",
          show:
            this.$store.getters["app/hasTenantsFlow"] &&
            !this.$store.getters["app/isDemo"],
        },
        {
          title: "members",
          link: "/platform/members",
          show: this.$store.getters["app/isWASP"],
        },
        {
          title: "live_streaming",
          link: "/platform/streaming",
          show: true,
        },
        {
          title: "promo",
          link: "/platform/promo/history",
          show:
            this.$store.getters["app/hasPromoAccess"] &&
            !this.$store.getters["app/isDemo"],
        },
        {
          title: "settings",
          link: "/platform/settings",
          show:
            this.$store.getters["app/isSuperAdmin"] && !this.$store.getters["app/isDemo"],
        },
        {
          title: "wallet",
          link: "/platform/wallet",
          show:
            (this.$store.getters["app/hasTenantsFlow"] ||
              this.$store.getters["app/isCyber"]) &&
            !this.$store.getters["app/isDemo"],
        },
        {
          title: "occupancy",
          link: "/platform/occupancy",
          show: this.$store.getters["app/isCyber"] && !this.$store.getters["app/isDemo"],
        },
        {
          title: "devices",
          link: "/platform/devices",
          show:
            (this.$store.getters["app/isCyber"] || this.$store.getters["app/isSpot"]) &&
            !this.$store.getters["app/isDemo"],
        },
        // {
        //   title: "users",
        //   link: "/platform/users",
        //   show: this.$store.getters["app/isCyber"] && !this.$store.getters["app/isDemo"],
        // },
        {
          title: "logbook",
          link: "/platform/logbook",
          show: this.$store.getters["app/hasLogbook"],
        },
        {
          title: "parking_payment",
          link: "/platform/parking-payment",
          show: this.$store.getters["app/isItt"] || this.$store.getters["app/isGoldin"],
        },
        {
          title: "blacklist",
          link: "/platform/black-list",
          show: this.$store.getters["app/isReadOnlyBlackList"],
        },
      ];
    },
    selectedIcons() {
      return {
        dashboard:
          "https://storage.googleapis.com/parkingbnb-html-images/%20Dashboard%20green.svg",
        your_parking:
          "https://storage.googleapis.com/parkingbnb-html-images/%20Parking%20lots%20green.svg",
        subscriptions: require("@/assets/images/onlinepassGreen.webp"),
        zone_history: require("@/assets/images/Zone history green.svg"),
        parking_history:
          "https://storage.googleapis.com/parkingbnb-html-images/%20Parking%20history%20green.svg",
        visit_history: require("@/assets/images/Zone history green.svg"),
        over_parking: require("@/assets/images/Zone history green.svg"),
        live_streaming:
          "https://storage.googleapis.com/parkingbnb-html-images/streaming%20green.svg",
        promo: require("@/assets/images/Promo Green.svg"),
        settings: require("@/assets/images/settingsGreen.svg"),
        wallet:
          "https://storage.googleapis.com/parkingbnb-html-images/ Payments green.svg",
        occupancy: require("@/assets/images/vehicles.webp"),
        devices: require("@/assets/images/list.webp"),
        companies: require("@/assets/images/company.webp"),
        users: require("@/assets/images/usersGreen.webp"),
        logbook: require("@/assets/images/logbook-green.svg"),
        blacklist: require("@/assets/images/blacklist.svg"),
        parking_payment:
          "https://storage.googleapis.com/parkingbnb-html-images/ Payments green.svg",
        members: require("@/assets/images/user-check-green.svg"),
      };
    },
    defaultIcons() {
      return {
        dashboard:
          "https://storage.googleapis.com/parkingbnb-html-images/%20Dashboard.svg",
        your_parking:
          "https://storage.googleapis.com/parkingbnb-html-images/Parking%20lots.svg",
        subscriptions: require("@/assets/images/onlinepassWhite.webp"),
        zone_history: require("@/assets/images/Zone history.svg"),
        parking_history:
          "https://storage.googleapis.com/parkingbnb-html-images/%20Parking%20history.svg",
        visit_history: require("@/assets/images/Zone history.svg"),
        over_parking: require("@/assets/images/Zone history.svg"),
        live_streaming:
          "https://storage.googleapis.com/parkingbnb-html-images/streaming.svg",
        promo: require("@/assets/images/Promo.svg"),
        settings: require("@/assets/images/settings.svg"),
        wallet: "https://storage.googleapis.com/parkingbnb-html-images/ Payments.svg",
        occupancy: require("@/assets/images/vehicles-white.webp"),
        devices: require("@/assets/images/list-white.webp"),
        companies: require("@/assets/images/company-white.webp"),
        users: require("@/assets/images/usersWhite.webp"),
        logbook: require("@/assets/images/logbook-white.svg"),
        blacklist: require("@/assets/images/blacklist-white.svg"),
        parking_payment:
          "https://storage.googleapis.com/parkingbnb-html-images/ Payments.svg",
        members: require("@/assets/images/user-check.svg"),
      };
    },
  },
  data() {
    return {
      mini: false,
      drawer: null,
      item: "",
    };
  },
  watch: {
    mini(newValue) {
      this.$emit("drawerMini", newValue);
    },
  },
  methods: {},
};
</script>
